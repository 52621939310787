<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container" :class="{ dashboasrd: proposals.length > 0 }">
        <div class="mb-4 alert alert-warning" v-if="profile.status === 1">
          Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá postar projetos.
        </div>
        <div class="row" v-else>
          <div class="col-lg-8">
            <h1>Últimos projetos</h1>
            <NewestProjects :list="this.projects" />
          </div>
          <div class="col-lg-4 col-sm-12">
            <h1>Últimas propostas</h1>
            <NewestProposals :list="this.proposals" />
          </div>
        </div>
        <!-- Card -->
        <div class="card-list-group">
          <div class="row g-4">
            <div class="col-lg-4 col-md-12 card-list">
              <div class="card">
                <div class="card-icons">
                  <i class="fa-solid fa-sheet-plastic icons"></i>
                </div>
                <div class="card-body">
                  <h2>Publique um projeto</h2>
                  <p>
                    Crie um projeto descrevendo o que você precisa.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 card-list">
              <div class="card">
                <div class="card-icons">
                  <i class="fa-solid fa-arrow-up-from-bracket icons"></i>
                </div>
                <div class="card-body">
                  <h2>Receba propostas</h2>
                  <p>
                    Os profissionais interessados no projeto enviarão uma proposta para você.
                  </p>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 card-list">
              <div class="card">
                <div class="card-icons">
                  <i class="fa-solid fa-users icons"></i>
                </div>
                <div class="card-body">
                  <h2>Escolha até 4 profissionais</h2>
                  <p>
                    Create a project describing what you need, put as much
                    detail as you can, this will help you find the talent that
                    best fits your needs.
                  </p>
                </div>
              </div>
            </div> -->

            <div class="col-lg-4 col-md-12 card-list">
              <div class="card ex-icon">
                <div class="card-icons">
                  <i class="fa-solid fa-comment icons"></i>
                </div>
                <div class="card-body">
                  <h2>Negocie</h2>
                  <p>
                    Negocie diretamente e escolha o profissional certo para você.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import NewestProjects from "../dashboard/NewestProjects.vue";
import NewestProposals from "../dashboard/NewestProposals.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, NewestProjects, NewestProposals },

  created() {
    this.$store.commit("setActiveSection", "DashboardPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadClientData();
  },

  data() {
    return {
      authUser: null,
      profile: null,
      removePicture: false,
      name: null,
      picture: null,
      email: null,
      whatsapp: null,
      acceptTerms: false,
      receiveNews: false,
      projects: [],
      proposals: [],
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClientData() {
      this.$store.commit("setLoading", true);

      try {
        let clientData = await ClientManager.getClientData();
        clientData.profile.contact_info = JSON.parse(
          clientData.profile.contact_info
        );

        this.profile = clientData.profile;
        this.projects = clientData.newest_projects;
        this.proposals = clientData.newest_proposals;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setActiveProjectList", this.projects);
        this.$store.commit("setLoading", false);

        if (this.projects.length === 0 && this.profile.status === 2) {
          this.showCreateProject(ClientManager);
        }
      } catch (error) {
        this.handleAPIError(error, null);
      }
    }
  },
};
</script>
