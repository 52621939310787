import Config from "../../../resources/config.js"

async function handleResponse(response) {
  if (response.status === 200) {
    let responseObject = await response.json();
    return responseObject.responseData;
  } else if (response.status === 400) {
    throw await response.json();
  } else {
    throw response;
  }
}

export default {

  async getClientData() {
    let url = `${Config.apiHost}/client/dashboard`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProfile() {
    let url = `${Config.apiHost}/client/get-profile`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getNotifications(lastNotificationId) {
    let url = `${Config.apiHost}/client/get-notifications?last_id=${lastNotificationId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async setLastSeenNotifications(lastNotificationId) {
    let url = `${Config.apiHost}/client/set-last-seen-notifications?last_id=${lastNotificationId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async updateProfile(clientDetails) {
    let url = `${Config.apiHost}/client/update-profile`;
    let formData = new FormData();
    let pictureFile = clientDetails.picture;
    let removePicture = clientDetails.removePicture;

    delete clientDetails['picture'];
    delete clientDetails['removePicture'];
    if (clientDetails.name) {
      formData.append('name', clientDetails.name);
    }

    if (clientDetails.contact_info) {
      formData.append('contact_info', JSON.stringify(clientDetails.contact_info));
    }

    if (pictureFile) {
      formData.append('picture_file', pictureFile);
    } else if (removePicture) {
      formData.append('remove_picture', true);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getProjects(status) {
    let url = `${Config.apiHost}/client/get-projects?n=100`;

    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response)
  },

  async addProject(projectDetails, assistant) {
    let url = `${Config.apiHost}/client/add-project`;
    let formData = new FormData();

    formData.append('project_title', projectDetails.title);
    formData.append('project_description', projectDetails.description);

    if (assistant) {
      formData.append('invited_assistant_id', assistant.id);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getProject(projectId, proposalCount) {
    let url = `${Config.apiHost}/client/get-project/${projectId}?proposal_count=${proposalCount}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProposal(projectId, assistantId) {
    let url = `${Config.apiHost}/client/get-proposal/${projectId}/${assistantId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProposals(projectId, lastProposal, count) {
    let url = `${Config.apiHost}/client/get-proposals/${projectId}?n=${count}`;

    if (lastProposal) {
      url += '&lv=' + lastProposal.score + "&lp=" + lastProposal.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async searchAssistants(keywords, includeProfile, lastAssistant, count) {
    let url = `${Config.apiHost}/client/search-assistants?n=${count}`;

    if (lastAssistant) {
      url += '&lv=' + lastAssistant.score + "&lp=" + lastAssistant.position;
    }

    let formData = new FormData();

    if (keywords && keywords.length > 0) {
      formData.append('keywords', keywords);
    }

    formData.append('include_profile', includeProfile);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getAssistantProfile(assistantId) {
    let url = `${Config.apiHost}/client/get-assistant-profile/${assistantId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async inviteAssistant(assistantId, projectId) {
    let url = `${Config.apiHost}/client/invite-assistant/${assistantId}/${projectId}`;
    let formData = new FormData();

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async releaseContact(assistantId, projectId) {
    let url = `${Config.apiHost}/client/release-contact/${assistantId}/${projectId}`;
    let formData = new FormData();

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getMoreProjects(lastProject) {
    let url = `${Config.apiHost}/client/get-projects?status=1`;

    if (lastProject) {
      url += '&lv=' + lastProject.last_update_timestamp + "&lp=" + lastProject.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    if (response.status === 200) {
      let responseObject = await response.json();
      if (responseObject.errorCode) {
        throw responseObject;
      }
      return responseObject.responseData;
    } else {
      throw response;
    }
  },
}