export function toggle() {
    var content = document.getElementById("navbarNav");
    if (content.style.display === "none") {
        content.style.display = "block";
    } else {
        content.style.display = "none";
    }
}

export function popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

$(document).ready(function () {
    $(document).on('shown.bs.modal', function (event) {
        let target = event.target;
        if (target) {
            let initFunc = $(target).data('initFunction');
            if (initFunc) {
                initFunc();
            }
        }
    });

    $(document).click(function (e) {
        if ($(e.target).closest(".popover").length) {
            return;
        }
        $("[data-bs-toggle='popover']").popover('hide');
    });
});