<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <div class="container-fluid body-container mb-0">
        <div v-if="activeUser.status == 1">
          <div class="center-block">
            <div>
              <div class="alert alert-warning">
                Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá contactar assistentes.
              </div>
            </div>
          </div>
        </div>

        <div class="element" v-else>
          <div class="search-box-container">
            <div class="nav">
              <div>
                <h2>Encontre talentos</h2>
                <p>Encontre os melhores talentos da Assistência Virtual aqui</p>
              </div>
              <div class="search-box">
                <input type="text" id="searchTalentInput" placeholder="Buscar talentos" @input="debounceSearch"
                  :class="{ disabled: searching || loadingMore }" :disabled="searching || loadingMore" />
                <button type="submit" @click="searchAssistants(false)" :disabled="searching || loadingMore"
                  :class="{ disabled: searching || loadingMore }">
                  <i class="fa fa-search icons"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="talent-page" v-if="searching">
            <div class="loading_container" style="height: auto; margin-top: 30px; overflow: hidden">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
          <div class="talent-page" v-else-if="searchError">
            <p class="mt-3 text-center text-danger" style="font-weight: bold">
              Ocorreu um erro ao buscar talentos.<br/>
              Por favor, tente novamente.<br/>
              Se o problema persistir, atualize a página.
            </p>
          </div>

          <div class="talent-page" v-else-if="assistants && assistants.length === 0">
            <p class="mt-3 text-center" style="font-weight: bold">
              Nenhum talento encontrado. Tente buscar com outros termos.
            </p>
          </div>
          <div class="talent-page" v-else>
            <div class="">
              <div class="col-lg-12 assistant-card contact" v-for="assistant in assistants" :key="assistant.id"
                :id="assistant.id">
                <Assistant :item="assistant" :truncateDescription="true" :addLinks="true" :projects="projects"  />
              </div>
            </div>
            <div class="page_number" style="margin-bottom: 30px">
              <button id="fetchMoreAssistants" class="btn see_more btn-small" :class="{ disabled: loadingMore }"
                @click="fetchMore()" v-if="hasMore">
                {{ loadingMore ? 'Carregando...' : 'Mostrar mais' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import Assistant from "../assistant/Assistant.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";
import debounce from "lodash.debounce";

const RESULT_COUNT = 10;

const debouncedHandler = debounce((event, func) => {
  func(event.target.value);
}, 500);

export default {
  components: { SideBar, TopBar, Assistant },

  beforeUnmount() {
    debouncedHandler.cancel();
  },

  created() {
    this.$store.commit("setActiveSection", "AssistantListPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadAssistants();
  },

  data() {
    return {
      searching: false,
      profile: null,
      assistants: [],
      projects: [],
      searchError: false,
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
  },

  methods: {
    async loadAssistants() {
      this.$store.commit("setLoading", true);

      try {
        let response = await ClientManager.searchAssistants(null, true, null, RESULT_COUNT);
        let profile = response.client;
        profile.contact_info = JSON.parse(profile.contact_info);
        this.profile = profile;
        this.assistants = response.assistants;
        this.projects = response.projects;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
        this.hasMore = this.assistants.length >= RESULT_COUNT;

        this.applyTruncation();
        let ctx = this;
        this.$nextTick(() => {
          ctx.initTooltips();
        });
      } catch (error) {
        this.handleAPIError(error, "encontre-talentos");
      }
    },

    debounceSearch(e) {
      debouncedHandler(e, () => {
        this.searchAssistants();
      });
    },

    async searchAssistants() {
      let ctx = this;
      ctx.searching = true;
      ctx.searchError = false;

      try {
        let response = await ClientManager.searchAssistants(
          $("#searchTalentInput").val(),
          false,
          null,
          RESULT_COUNT
        );

        this.assistants = response.assistants;
        this.hasMore = this.assistants.length >= RESULT_COUNT
        this.searching = false;

        ctx.$nextTick(() => {
          $("#searchTalentInput").focus();
          ctx.applyTruncation();
          ctx.initTooltips();
        });

      } catch (err) {
        ctx.searchError = true;
        ctx.searching = false;
      }
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastAssistant = null;
      if (this.assistants && this.assistants.length > 0) {
        lastAssistant = this.assistants[this.assistants.length - 1];
      }
      try {
        let response = await ClientManager.searchAssistants(
          $("#searchTalentInput").val(),
          false,
          lastAssistant,
          RESULT_COUNT
        );

        this.assistants = this.assistants.concat(response.assistants);
        if (response.assistants.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.loadingMore = false;

        if (response.assistants.length > 0) {
          ctx.$nextTick(() => {
            ctx.applyTruncation(() => {
              ctx.initTooltips();
              let lastCard = $(".talent-page").find('#' + response.assistants[0].id).get(0);
              let navBar = $(".body-container .element .nav").get(0);
              let scrollDiv = lastCard.offsetTop - navBar.offsetHeight - 116;
              window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
            });
          });
        }
      } catch (err) {
        ctx.loadingMore = false;
        console.log(err);
      }
    },
  },
};
</script>
