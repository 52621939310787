<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-danger" style="margin-bottom:30px" v-if="project.status === 3">
          Seu projeto está inativo e você não poderá iniciar contatos.<br /> Entre em
          contato com o suporte para maiores informações.
        </div>

        <div class="tab">
          <h1>Projeto</h1>
        </div>
        <div class="project-container project_area section" style="margin: 0">
          <div class="left">
            <div class="project_info">
              <div class="project_heading break-word">
                <h2 class="mb-0">{{ project.title }}</h2>
                <p class="date m-0 p-0 mb-2" style="font-size: 10pt">
                  ID: {{ project.id }}
                </p>
                <p class="date">
                  Última atualização:
                  {{ formatTimestamp(project.last_update_timestamp) }}
                </p>
              </div>

              <div class="wrap-box">
                <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(project.description)">
                </p>
                <label></label>
              </div>

            </div>
          </div>
          <div class="right">
            <div class="proposal box mt-0">
              <h1>Propostas recebidas</h1>
              <h1 class="proposal_number">{{ project.active_proposals }}</h1>
            </div>
            <div class="status box mb-0">
              <h1>
                Status:
                <span class="btn" :class="getBtnStatusClass(project.status)">{{
                  formatProjectStatus(project.status)
                }}</span>
              </h1>
            </div>
          </div>
        </div>

        <div v-if="project.status === 4">
          <div v-if="!this.proposal.released">
            <div class="alert alert-warning mt-4">Este projeto foi encerrado e você não
              solicitou o contato de <strong>{{ this.proposal.name }}</strong>.</div>
            <div class="tab">
              <h1>Proposta</h1>
            </div>
            <Proposal :project="this.project" :proposal="this.proposal" class="section" />
          </div>

          <div v-else>
            <div class="alert alert-warning mt-4">Este projeto foi encerrado e você solicitou o contato de <strong>{{
              this.proposal.name }}</strong>.</div>
            <div class="tab">
              <h1>Proposta</h1>
            </div>
            <Proposal :project="this.project" :proposal="this.proposal" class="section" />
          </div>
        </div>
        <div style="margin-top: 20px" v-else-if="project.status !== 1">

          <div class="alert alert-warning mt-3" v-if="contactsLeft < 1 && project.status === 2">
            Você atingiu o limite de
            {{ formatPlural(project.max_contacts, "contato", "contatos") }} para
            este projeto.
          </div>

          <div class="tab">
            <h1>Proposta</h1>
          </div>
          <Proposal :project="this.project" :proposal="this.proposal" class="section" />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import Proposal from "../project/Proposal.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, Proposal },

  created() {
    this.$store.commit("setActiveSection", "ProposalPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadProject();
  },

  data() {
    return {
      project: null,
      proposal: null
    };
  },


  computed: {
    loading() {
      return this.$store.state.loading;
    },

    activeUser() {
      return this.$store.state.activeUser;
    },

    hasContacts() {
      if (this.project && this.project.released_contacts > 0) {
        return true;
      }
      return false;
    },
    contactsLeft() {
      if (this.project) {
        return this.project.max_contacts - this.project.released_contacts;
      }
      return 0;
    },
  },

  methods: {
    async loadProject() {
      this.$store.commit("setLoading", true);

      try {
        let response = await ClientManager.getProposal(
          this.$route.params.projectId,
          this.$route.params.assistantId
        );
        let profile = response.client;
        profile.contact_info = JSON.parse(profile.contact_info);

        this.project = response.project;
        this.proposal = response.proposal;

        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
        this.applyTruncation();

        let ctx = this;

        this.$nextTick(() => {
          ctx.initTooltips();
        });
      } catch (error) {
        this.handleAPIError(error, "proposta/" + this.$route.params.projectId + "/" + this.$route.params.assistantId);
      }
    },
  },
};
</script>
