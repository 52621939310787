<template>
  <div class="talent_profile proposallls">
    <div class="talent-info">
      <div class="freelancer_info">
        <div class="freelancer">
          <a :href="assistantLink()" target="_blank">
            <img class="freelancer_img" :src="getImageURL(assistant.picture)" v-if="assistant.picture" alt="" />
            <img class="freelancer_img empty-image" v-else alt="" />
          </a>
          <div class="info">
            <div>
              <a :href="assistantLink()" target="_blank">
                <h2 class="break-word">
                  {{ assistant.name }}
                </h2>
              </a>
              <a :href="assistantLink()" target="_blank">
                <p class="break-word title">{{ assistant.title }}</p>
              </a>
            </div>
            <div class="down-icons-list">
              <div class="ex_icon" v-if="assistant.cert_av || assistant.cert_ap">
                <span tabindex="0" :data-bs-placement="addLinks ? 'bottom' : 'bottom'" data-bs-toggle="popover"
                  :data-bs-title="getCervAVTitle()" data-bs-custom-class="certav-popover"
                  :data-bs-content="getCertAVDescription()" data-bs-html="true" v-if="assistant.cert_av"
                  class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>

                <span tabindex="0" :data-bs-placement="addLinks ? 'bottom' : 'bottom'" data-bs-toggle="popover"
                  :data-bs-title="getCervAPTitle()" :data-bs-content="getCertAPDescription()" data-bs-html="true"
                  data-bs-custom-class="certap-popover" v-if="assistant.cert_ap" class="cert_ap"><i
                    class="fa-solid fa-star icons"></i></span>
                <!-- <span><i class="fa-solid fa-trophy icons"></i></span> -->
              </div>
              <div class="star">                                
                  <i :class="star.class" class="icons" v-for="star in getRatingStars(assistant.feedback_rating)" :key="star.id"></i>
                  <span class="rating"> ({{ assistant.feedback_rating.toFixed(2) }})</span>
              </div>
            </div>
            <p class="country">{{ assistant.location }}</p>
          </div>
        </div>
        <div class="btn" @click.prevent="hireAssistant(assistant)">
          Convidar para um projeto
        </div>
      </div>
    </div>
    <div class="keywords-group">
      <div class="ky_btn" v-if="assistant.area_ap">Assistência Pessoal</div>
      <div class="ky_btn" v-if="assistant.area_ae">Secretariado Executivo</div>
      <div class="ky_btn" v-if="assistant.area_sa">Suporte Administrativo</div>
      <div class="ky_btn" v-if="assistant.area_sr">Secretariado remoto</div>
      <div class="ky_btn" v-if="assistant.area_si">
        Suporte para Infoprodutores
      </div>
      <div class="ky_btn" v-if="assistant.area_ed">Entrada de dados</div>
    </div>
    <div :class="{ 'wrap-box short': truncateDescription }" class="mt-4" v-if="hideDescription !== true">
      <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(assistant.description)">
      </p>
      <label></label>
    </div>
  </div>
</template>

<script>
import ClientManager from "../../../js/services/client.js";

export default {
  props: ["item", "truncateDescription", "hideDescription", "addLinks", "projects"],
  data() {
    return {
      assistant: this.item,
    };
  },
  computed: {},
  methods: {
    assistantLink() {
      if (this.addLinks) {
        return '/assistente/' + this.assistant.id;
      } else {
        return null;
      }
    },

    formatStatus(status) {
      switch (status) {
        case 1:
          return "EM ANÁLISE";
        case 2:
          return "ATIVO";
        case 3:
          return "REMOVIDO";
        default:
          return "DESCONHECIDO";
      }
    },
    defineClass(status) {
      switch (status) {
        case 1:
          return "text-warning";
        case 2:
          return "text-success";
        case 3:
          return "text-danger";
        default:
          return "text-dark";
      }
    },
    async selectNewStatus(event) {
      let selectedStatus = event.target.value;
      this.loading = true;
      this.error = false;
      try {
        this.projects = await ClientManager.getProjects(selectedStatus);
      } catch (err) {
        console.log(err);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    createProject() {
      this.showCreateProject(ClientManager, null);
    },

    async hireAssistant(assistant) {
      this.showInviteAssistant(ClientManager, assistant, this.projects);
    },

    gotoProject(project) {
      window.location = "/projeto/" + project.id;
    },
  },
};
</script>
