<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">


        <div v-if="activeUser.status == 1">
          <div class="center-block">
            <div>
              <div class="alert alert-warning">
                Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá postar projetos.
              </div>
            </div>
          </div>
        </div>

        <div class="element" v-else>
          <div class="search-box-container">
            <div class="nav" style="border-radius: 8px 8px 0 0; padding: 15px">
              <h1>
                {{ projectSummary }}
                <span v-if="!listLoading && !listError" class="fs-6">({{ projectCount }})</span>
              </h1>
              <select class="form-select" v-model="projectStatus" @change="selectNewStatus($event)"
                :disabled="listLoading">
                <option value="">Todos</option>
                <option value="1">Em análise</option>
                <option value="2">Ativos</option>
                <option value="4">Encerrados</option>
                <option value="3">Desativados</option>
              </select>
            </div>
          </div>
          <div class="project-list">
            <div class="post_project post_project-active" style="padding:0; height: auto"
              :class="{ nopro: projects && projects.length === 0 }">
              <div class="loading_container" v-if="listLoading" style="height: 200px">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
              <div v-else-if="listError" class="create-project">
                <div class="card">
                  <div class="card-body">
                    <h2 class="text-danger">Erro</h2>
                    <p class="text-danger">
                      Ocorreu um erro ao atualizar os projetos.<br />
                      Por favor, tente novamente.<br />
                      Se o problema persistir, atualize a página.
                    </p>
                  </div>
                </div>
              </div>
              <div class="create-project mb-3" v-else-if="!projects || projects.length === 0">
                <div class="card">
                  <img src="assets/img/project.png" alt="" />
                  <div class="card-body">
                    <h2 class="light">Nenhum projeto encontrado</h2>
                  </div>
                </div>
              </div>
              <div class="active-project" style="padding: 0" v-else>
                <a class="card" style="position: static;" :href="'/projeto/' + project.id" v-for="project in projects"
                  :key="project.id">
                  <div class="name">
                    <p>
                      Título <br />
                      <span>{{ project.title }}</span>
                    </p>
                  </div>
                  <div class="date">
                    <p>
                      Última atualização <br />
                      <span>{{
                        formatTimestamp(project.last_update_timestamp)
                      }}</span>
                    </p>
                  </div>
                  <div class="count">
                    <p>
                      Propostas <br />
                      <span>{{ project.active_proposals }}</span>
                    </p>
                  </div>
                  <div class="status">
                    <p>
                      Status <br />
                      <span :class="getTextStatusClass(project.status)">
                        {{ formatProjectStatus(project.status) }}</span>
                    </p>
                  </div>

                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ProjectListPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadProjects();
  },

  data() {
    return {
      projectStatus: "",
      projects: null,
      listLoading: false,
      listError: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },

    activeUser() {
      return this.$store.state.activeUser;
    },

    projectCount() {
      if (this.projects) {
        return this.projects.length;
      }
      return 0;
    },

    projectSummary() {
      return "Projetos";
    },
  },

  methods: {
    async loadProjects() {
      this.$store.commit("setLoading", true);
      try {
        let response = await ClientManager.getProjects();
        let profile = response.profile;
        profile.contact_info = JSON.parse(profile.contact_info);

        this.projects = response.projects;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setActiveProjectList", this.projects);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
        if (this.$route.query.new === '1') {
          this.showCreateProject(ClientManager, null);
        }
      } catch (error) {
        this.handleAPIError(error, "projetos");
      }
    },

    async selectNewStatus(event) {
      let selectedStatus = event.target.value;
      this.listLoading = true;
      this.listError = null;
      try {
        let response = await ClientManager.getProjects(selectedStatus);
        this.projects = response.projects;
        if (selectedStatus === "" || selectedStatus === "1") {
          this.$store.commit("setActiveProjectList", this.projects);
        } else {
          this.$store.commit("setActiveProjectList", null);
        }
      } catch (err) {
        console.log(err);
        this.listError = err;
      } finally {
        this.listLoading = false;
      }
    }
  },
};
</script>
