<template>
  <div class="create-project" style="margin-top:0" v-if="proposals.length === 0">
    <div class="tab">
      <h1>Propostas</h1>
    </div>
    <div class="section">
      <div class="card p-4">
        <img src="/assets/img/proposal.svg" alt="" />
        <h2 class="light mt-2">
          Nenhuma proposta recebida
        </h2>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="alert alert-warning mt-3" v-if="hasContacts && contactsLeft > 0 && project.status === 2">
      Você já solicitou o contato de
      {{
        formatPlural(project.released_contacts, "assistente", "assistentes")
      }}
      neste projeto. Você ainda pode conversar com mais
      {{ formatPlural(contactsLeft, "assistente", "assistentes") }}.
    </div>
    <div class="alert alert-warning mt-3" v-else-if="hasContacts && contactsLeft < 1 && project.status === 2">
      Você atingiu o limite de
      {{ formatPlural(project.max_contacts, "contato", "contatos") }} para
      este projeto.
    </div>
    <div class="alert alert-warning mt-3" v-else-if="contactsLeft > 0 && project.status === 2">
      Você pode conversar com até
      {{ formatPlural(contactsLeft, "assistente", "assistentes") }}.
    </div>
    <h1 v-if="!hideTitle">Propostas</h1>

    <div class="contact" v-for="proposal in proposals" :key="proposal.project_id + '_' + proposal.assistant_id"
      :id="proposal.assistant_id">
      <Proposal :project="project" :proposal="proposal" />
    </div>

    <div class="page_number">
      <button id="fetchMoreProposals" class="btn see_more btn-small" @click="fetchMore()" v-if="hasMore"
        :class="{ disabled: loadingMore }">
        {{ loadingMore ? 'Carregando...' : 'Mostrar mais' }}
      </button>
    </div>

  </div>
</template>

<script>
import { toHandlers } from "vue";
import ClientManager from "../../../js/services/client.js";
import Proposal from "./Proposal.vue";

export default {
  props: ["project", "proposalCount", "hideTitle"],
  data() {
    return {
      loading: false,
      error: false,
      loadingMore: false,
      hasMore: this.project.active_proposals >= this.proposalCount,
      proposals: this.project.proposals,
    };
  },
  components: { Proposal },
  computed: {
    activeUser() {
      return this.$store.state.activeUser;
    },
    hasContacts() {
      if (this.project && this.project.released_contacts > 0) {
        return true;
      }
      return false;
    },
    contactsLeft() {
      if (this.project) {
        return this.project.max_contacts - this.project.released_contacts;
      }
      return 0;
    },
  },
  methods: {

    contactAssistant(proposal) {
      if (this.project.status === 3) {
        return;
      }

      if (this.project.released_contacts >= this.project.max_contacts) {
        this.showError(
          "Você já atingiu o limite de " +
          this.formatPlural(
            this.project.max_contacts,
            "contato",
            "contatos"
          ) +
          " para este projeto."
        );
      } else {
        this.showContactAssistant(
          ClientManager,
          this.activeUser,
          this.project,
          proposal
        );
      }
      return false;
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastProposal = null;
      if (this.proposals && this.proposals.length > 0) {
        lastProposal = this.proposals[this.proposals.length - 1];
      }
      try {
        let response = await ClientManager.getProposals(this.project.id, lastProposal, this.proposalCount);
        this.proposals = this.proposals.concat(response.proposals);
        if (response.proposals.length < this.proposalCount) {
          this.hasMore = false;
        }

        if (response.proposals.length > 0) {
          ctx.applyTruncation(() => {
            ctx.initTooltips();

            let lastCard = $(".proposal-page").find('#' + response.proposals[0].assistant_id).get(0);
            let scrollDiv = lastCard.offsetTop - 86;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
          });
        }

      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },
  },
};
</script>
