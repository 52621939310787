
let config = {
    development: {
        apiHost: 'http://localhost:3000',
        assetsHost: "https://virtap.s3.amazonaws.com/test",
        loginPage: "http://localhost:8000/login.html",
        signupPage: "http://localhost:8000/cadastro.html"
    },
    production: {
        apiHost: 'https://api.virtap.com.br',
        assetsHost: "https://virtap.s3.amazonaws.com/production",
        loginPage: "https://www.virtap.com.br/login",
        signupPage: "https://www.virtap.com.br/cadastro"
    }
}

export default config[process.env.NODE_ENV];