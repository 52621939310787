import DashboardPage from "../../vue/components/pages/DashboardPage.vue";
import ProfilePage from "../../vue/components/pages/ProfilePage.vue";
import ProjectListPage from "../../vue/components/pages/ProjectListPage.vue";
import ProjectPage from "../../vue/components/pages/ProjectPage.vue";
import ProposalPage from "../../vue/components/pages/ProposalPage.vue";
import AssistantListPage from "../../vue/components/pages/AssistantListPage.vue";
import AssistantPage from "../../vue/components/pages/AssistantPage.vue";

import NotFoundPage from "../../vue/components/pages/NotFoundPage.vue";
import LoginSuccessPage from "../../vue/components/pages/LoginSuccessPage.vue";
import LoginErrorPage from "../../vue/components/pages/LoginErrorPage.vue";

const routes = [
    { path: ``, component: DashboardPage },
    { path: `/perfil`, component: ProfilePage },
    { path: `/projetos`, component: ProjectListPage },
    { path: `/encontre-talentos`, component: AssistantListPage },
    { path: `/assistente/:assistantId`, component: AssistantPage },
    { path: `/projeto/:projectId`, component: ProjectPage },
    { path: `/proposta/:projectId/:assistantId`, component: ProposalPage },
    { path: `/login-feito`, component: LoginSuccessPage },
    { path: `/erro-login`, component: LoginErrorPage },
    { path: "/:catchAll(.*)", component: NotFoundPage }
];

export default routes;