<template>
  <div class="post_project user mt-2 mb-4" style="height:auto;" v-if="projectCount === 0">
    <div class="no-projects">
      <div>
        <div style="width:auto;">
          <img src="/assets/img/project.png" width="100" />
          <h2 class="mt-2 light">
            Nenhum projeto encontrado
          </h2>
          <p>Poste um projeto e encontre o profissional perfeito para sua
            necessidade!
          </p>
          <a @click="createProject" class="btn mt-2">Postar um projeto</a>
        </div>
      </div>
    </div>
  </div>
  <div class="active-project mt-2 project-list" v-else style="padding:0">
    <a class="card m-0 mb-3" :href="'/projeto/' + project.id" v-for="project in projects" :key="project.id">
      <div class="name">
        <p>
          Título <br />
          <span>{{ project.title }}</span>
        </p>
      </div>
      <div class="date">
        <p>
          Última atualização <br />
          <span>{{
            formatTimestamp(project.last_update_timestamp)
          }}</span>
        </p>
      </div>
      <div class="count">
        <p>
          Propostas <br />
          <span>{{ project.active_proposals }}</span>
        </p>
      </div>
      <div class="status">
        <p>
          Status <br />
          <span :class="getTextStatusClass(project.status)">
            {{ formatProjectStatus(project.status) }}</span>
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import ClientManager from "../../../js/services/client.js";

export default {
  props: ["list"],
  data() {
    return {
      loading: false,
      error: false,
      projects: this.list,
    };
  },
  computed: {
    projectCount() {
      if (this.projects) {
        return this.projects.length;
      }
      return 0;
    },

    activeUser() {
      return this.$store.state.activeUser;
    },
  },
  methods: {
    async selectNewStatus(event) {
      let selectedStatus = event.target.value;
      this.loading = true;
      this.error = false;
      try {
        this.projects = await ClientManager.getProjects(selectedStatus);
      } catch (err) {
        console.log(err);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    createProject() {
      if (this.activeUser.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado pela nossa equipe, você poderá postar projetos."
        );
        return;
      }
      this.showCreateProject(ClientManager, null);
    }
  },
};
</script>
