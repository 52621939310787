<template>
  <div class="talent_profile proposallls">
    <div class="talent-info">
      <div class="freelancer_info">
        <div class="freelancer">

          <a target="_blank" :href="'/assistente/' + proposal.assistant_id">

            <img class="freelancer_img" :src="getImageURL(proposal.picture)" v-if="proposal.picture" alt="" />
            <img class="freelancer_img empty-image" v-else alt="" />

          </a>
          <div class="info">
            <div>
              <a target="_blank" :href="'/assistente/' + proposal.assistant_id">
                <h2 class="break-word">
                  {{ proposal.name }}
                </h2>
              </a>
              <a target="_blank" :href="'/assistente/' + proposal.assistant_id">
                <p class="break-word title">{{ proposal.title }}</p>
              </a>
            </div>
            <div class="down-icons-list">
              <div class="ex_icon" v-if="proposal.cert_av || proposal.cert_ap">

                <span tabindex="0" data-bs-placement="bottom" data-bs-toggle="popover" :data-bs-title="getCervAVTitle()"
                  data-bs-custom-class="certav-popover" :data-bs-content="getCertAVDescription()" data-bs-html="true"
                  v-if="proposal.cert_av" class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>

                <span tabindex="0" data-bs-placement="bottom" data-bs-toggle="popover" :data-bs-title="getCervAPTitle()"
                  :data-bs-content="getCertAPDescription()" data-bs-custom-class="certap-popover" data-bs-html="true"
                  v-if="proposal.cert_ap" class="cert_ap"><i class="fa-solid fa-star icons"></i></span>
                <!-- <span><i class="fa-solid fa-trophy icons"></i></span> -->
              </div>
              <div class="star">                                
                  <i :class="star.class" class="icons" v-for="star in getRatingStars(proposal.feedback_rating)" :key="star.id"></i>
                  <span class="rating"> ({{ proposal.feedback_rating.toFixed(2) }})</span>
              </div>
            </div>
            <p class="country">{{ proposal.location }}</p>
          </div>
        </div>
        <a class="btn" @click.stop="contactAssistant(proposal)" v-if="!proposal.released"
          :class="{ disabled: project.status === 4 || project.status == 3 || project.released_contacts >= project.max_contacts }"
          :disabled="project.status == 3 || project.status === 4" style="pointer-events: all">Conversar
          com assistente</a>
        <a class="btn contact-released" v-else>Contato iniciado</a>
      </div>
    </div>


    <div class="keywords-group">
      <div class="ky_btn" v-if="proposal.area_ap">
        Assistência Pessoal
      </div>
      <div class="ky_btn" v-if="proposal.area_ae">
        Secretariado Executivo
      </div>
      <div class="ky_btn" v-if="proposal.area_sa">
        Suporte Administrativo
      </div>
      <div class="ky_btn" v-if="proposal.area_sr">
        Secretariado remoto
      </div>
      <div class="ky_btn" v-if="proposal.area_si">
        Suporte para Infoprodutores
      </div>
      <div class="ky_btn" v-if="proposal.area_ed">
        Entrada de dados
      </div>

    </div>


    <!-- <hr class="mt-5" /> -->
    <div class="description_part mt-4">
      <div class="wrap-box">
        <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(proposal.proposal)">
        </p>
        <label></label>
      </div>
    </div>
  </div>
</template>

<script>
import ClientManager from "../../../js/services/client.js";

export default {
  props: ["project", "proposal"],
  data() {
    return {
    };
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser;
    },


  },
  methods: {


    contactAssistant(proposal) {
      if (this.project.status === 3 ||this.project.status === 4 || this.project.released_contacts >= this.project.max_contacts) {
        return;
      }

      if (this.project.released_contacts >= this.project.max_contacts) {
        this.showError(
          "Você já atingiu o limite de " +
          this.formatPlural(
            this.project.max_contacts,
            "contato",
            "contatos"
          ) +
          " para este projeto."
        );
      } else {
        this.showContactAssistant(
          ClientManager,
          this.activeUser,
          this.project,
          proposal
        );
      }
      return false;
    }
  },
};
</script>
