<template>
  <div class="post_project user mt-2 mb-4" style="height:auto;">
    <div :class="{ 'no-proposals': !hasProposals, 'proposals-list dashboard pb-0': hasProposals }">
      <div :class="{ 'proposal-container': hasProposals }">
        <div v-if="!hasProposals">
          <img src="/assets/img/proposal.svg" />
          <h2 class="light">Nenhuma proposta</h2>
        </div>
        <a :href="'/proposta/' + proposal.project_id + '/' + proposal.assistant_id" v-else v-for="proposal in proposals"
          :key="proposal.id">
          <div class="proposal">
            <div class="user">
              <div class="freelancer-img">
                <div class="active-box active"></div>
                <img :src="getImageURL(proposal.picture)" v-if="proposal.picture" />
                <img v-else class="empty-image" />
              </div>
              <div class="proposal-info">
                <p class="name ellipsis" v-html="formatMultilineText(proposal.name)"></p>
                <p class="project ellipsis" v-html="formatMultilineText(proposal.title)">
                </p>
                <p class="messages ellipsis" v-html="formatMultilineText(proposal.proposal)"></p>
                <p class="date ellipsis">{{ formatElapsedTime(proposal.last_update_timestamp) }}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
  data() {
    return {
      proposals: this.list
    };
  },
  computed: {
    hasProposals() {
      return this.proposals && this.proposals.length > 0;
    },
  },
  methods: {

  },
};
</script>
