<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="client-page">
          <div class="alert alert-warning" style="margin-bottom: 30px" v-if="profile.status == 1">
            Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá editá-lo.
          </div>
          <div class="tab">
            <h1>Perfil</h1>
            <a class="btn btn-small" @click="editProfile()" :disabled="profile.status !== 2"
              :class="{ 'disabled': profile.status !== 2 }"><i class="fa fa-pencil" style="padding-right: 5px"></i>
              Editar</a>
          </div>
          <div class="talent_profile client_profile section" style="padding: 20px 0">
            <div class="talent-info" style="padding: 0 20px">
              <div class="freelancer_info">
                <div class="freelancer">
                  <a href="javascript:void(0)" @click="selectFile()" :class="{ 'disabled': profile.status !== 2 }">
                    <div class="img-container" :class="{ 'disabled': profile.status !== 2 }">
                      <img class="freelancer_img" :src="getImageURL(profile.picture)" v-if="profile.picture" alt="" />
                      <img class="freelancer_img empty-image" v-else alt="" />
                      <span>
                        <i class="fa fa-pencil"></i>
                      </span>
                      <input type="file" accept="image/*" id="finput" @change="uploadImage()" style="display:none">
                    </div>
                  </a>
                  <div class="info">
                    <div>
                      <h2>
                        {{ profile.name }}
                      </h2>
                    </div>

                    <div class="down-icons-list">
                      <div class="star">                                
                        <i :class="star.class" class="icons" v-for="star in getRatingStars(profile.feedback_rating)" :key="star.id"></i>
                        <span class="rating"> ({{ profile.feedback_rating.toFixed(2) }})</span>
                      </div>
                    </div>
               
                  </div>
                </div>
              </div>
            </div>
            <div class="p-info">
              <div class="act-project">
                <p>
                  Total de projetos:
                  <span>{{ profile.total_projects }}</span>
                </p>
              </div>
              <div>
                <p>
                  WhatsApp:
                  <span class="break-all">{{ formatNumber(
                    profile.contact_info.whatsapp
                  ) }}</span>
                </p>
              </div>
            </div>

          </div>

          <div class="seller_review">
              <div class="tab">
                <h1>Minhas avaliações <span class="text-muted fs-6">({{profile.reviews.length}})</span></h1>
              </div>
              <div class="section" style="padding:0" v-if="profile.reviews.length > 0">
                <div class="proposals-list"  style="height: auto;max-height: 100%; padding-bottom: 0">
                  <div class="proposal-container">
                    <div class="proposal" style="padding:20px;" v-for="review in profile.reviews" :key="review.project_id" @click="showProject(review.project_id)">
                      <div class="proposal-info">
                          <p class="name break-all" style="font-size:14.2px">{{review.title}}</p>
                          <p class="project" style="font-style: italic; font-size: 9pt;  display: flex; gap: 5px; flex-wrap: wrap; align-items: center" @click="showAssistant(review.assistant_id)">
                            <img class="freelancer_img" style="width:50px; height: 50px;" :src="getImageURL(review.picture)" v-if="review.picture"/>
                            <img class="empty-image" style="width:50px; height: 50px;" v-else/>
                            <span class="break-all">{{review.name}}</span>
                          </p>
                          <p class="project mt-1" style="margin-left: -5px">
                            <i :class="star.class" class="icons" v-for="star in getRatingStars(review.client_rating)" :key="star.id" style="font-size: 15px"></i>
                                <span class="rating"> ({{ review.client_rating.toFixed(2) }})</span>
                          </p>
                          <p class="date">{{formatElapsedTime(review.release_timestamp)}}</p>
                          <p class="project mt-2 break-all break-line">{{review.client_comment}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section text-center" v-else>
                <h2 class="light p-2">
                  <div class="d-flex" style="align-items: center; gap: 5px; flex-wrap: wrap; justify-content: center">
                    <i class="fa-regular fa-star" style="font-size: 30px"></i>
                    <span>Nenhuma avaliação</span>
                  </div>
                </h2>
              </div>
            </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import ClientManager from "../../../js/services/client.js";
import FileUtils from "../../../js/utils/file.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ProfilePage");
    this.$store.commit("setActiveProjectList", null);
    this.loadClientProfile();
  },

  data() {
    return {
      profile: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClientProfile() {
      this.$store.commit("setLoading", true);

      try {
        this.profile = await ClientManager.getProfile();
        this.profile.contact_info = JSON.parse(this.profile.contact_info);
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "perfil");
      }
    },

    selectFile() {
      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );

        return false;
      }
      document.getElementById('finput').click();
    },

    showAssistant(assistant_id) {
      window.open('/assistente/' + assistant_id);

    },

    showProject(project_id) {
      window.open('/projeto/' + project_id);
    },

    uploadImage() {

      let $ctx = this;

      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );
        return;
      }

      var fileinput = document.getElementById("finput");
      if (fileinput.files.length > 0) {
        let reader = new FileReader();
        reader.onload = function (event) {


          let $modal = $("#editImageModal");
          let cropper = null;

          let action = async function () {
            let base64 = await cropper.cropme('crop', 'base64');
            let compressedImage = await FileUtils.downscaleImage(base64, 'image/jpeg', 240, 0.9);
            let blob = FileUtils.dataURIToBlob(compressedImage);

            let clientDetails = {
              picture: new File([blob], "image.jpeg")
            }
            let profile = await ClientManager.updateProfile(clientDetails);
            profile.contact_info = JSON.parse(profile.contact_info);

            $ctx.profile = profile;
            $ctx.$store.commit("setActiveUser", profile);
            return true;
          };

          $('#editImgContainer').html('<div class="text-center">Carregando...</div>');

          $ctx.showModal(
            $modal,
            function () {
              $('#editImgContainer').html('<img data-img class=""></img>');
              let $img = $("#editImgContainer").find('img').attr('src', event.target.result);
              $img.on('load', function () {
                $img.off();
                cropper = $img.cropme({
                  container: {
                    width: '100%'
                  },
                  viewport: {
                    width: 240,
                    height: 240,
                    border: {
                      color: 'var(--bs-blue)'
                      //type: 'circle'
                    },

                  }, zoom: {
                    slider: true
                  },
                  rotation: {
                    slider: false
                  }



                });

              });
            },
            "Salvar",
            action,
            "Perfil atualizado.",
            "Ocorreu um erro editar o perfil.\nPor favor, tente novamente. Se o problema persistir, atualize a página.",
            function () {
              $("#finput").val("");
              if (cropper) {
                cropper.cropme('destroy');
              }

            }
          );

          // 
          // $("#editProfileImg").data('file', fileinput.files[0]);
        };
        reader.readAsDataURL(fileinput.files[0]);
      }
    },

    editProfile() {
      let $ctx = this;
      let $modal = $("#editProfileModal");
      let $name = $modal.find("[data-client-name]");
      let $whatsapp = $modal.find("[data-client-whatsapp]");

      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado pela nossa equipe, você poderá editá-lo."
        );
        return;
      }

      $modal.find(".item").removeClass("error");
      $name.val($ctx.profile.name);


      if (!$modal.data('init')) {
        let intl = window.intlTelInput($whatsapp[0], {
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
          autoInsertDialCode: true,
          initialCountry: "BR",
          separateDialCode: true
        });


        $whatsapp.on('blur', function () {
          intl.setNumber(intl.getNumber());
          if (intl.isValidNumber()) {
            $whatsapp.closest(".item").removeClass("error");
          } else {
            $whatsapp.closest(".item").addClass("error");
          }
        });

        $modal.data('init', intl);
      }

      let intl = $modal.data('init');

      intl.setNumber($ctx.profile.contact_info.whatsapp);



      let action = async function () {
        let clientDetails = {
          name: $name.val(),
          contact_info: {
          },
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (clientDetails.name.length < 10) {
          $name.closest(".item").addClass("error");
          hasError = true;
        }

        if ($whatsapp.val().length < 1 || !intl.isValidNumber()) {
          $whatsapp.closest(".item").addClass("error");
          hasError = true;
        } else {
          clientDetails.contact_info.whatsapp = intl.getNumber();
        }

        if (hasError) {
          return false;
        }

        let profile = await ClientManager.updateProfile(clientDetails);
        profile.contact_info = JSON.parse(profile.contact_info);

        $ctx.profile = profile;
        $ctx.$store.commit("setActiveUser", profile);

        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-client-name]").focus();
        },
        "Salvar",
        action,
        "Perfil atualizado.",
        "Ocorreu um erro editar seu perfil.\nPor favor, tente novamente.\nSe o problema persistir, atualize a página."
      );
    },
  },
};
</script>
