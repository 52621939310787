<template>
  <div class="side_navbar">
    <div class="user_profile">
      <a href="/perfil">
        <img :src="userPicture" v-if="userPicture" alt="" />
        <img class="empty-image" v-else alt="" />
      </a>
      <p class="client_name">{{ userName }}</p>

     <div class="review mb-3">
      <div class="star">                                
        <i :class="star.class" class="icons" v-for="star in getRatingStars(rating)" :key="star.id"></i>
        <span class="rating" style="font-size: 10pt"> ({{ rating.toFixed(2) }})</span>
      </div>
     </div>

      <div class="text-center mt-2">
        <a class="link" :href="logoutURL">Sair</a>
      </div>
    </div>
    <div class="nav">
      <ul>
        <li>
          <a class="nav-link" :class="{ active: this.section === 'DashboardPage' }" href="/"><i
              class="fa-solid fa-house icons"></i>Dashboard</a>
        </li>
        <li>
          <a class="nav-link" href="/perfil" :class="{ active: this.section === 'ProfilePage' }"><i
              class="fa-solid fa-user icons" style="padding-left: 2px; margin-right: 12px"></i>Perfil</a>
        </li>
        <li>
          <a class="nav-link" href="/projetos"
            :class="{ active: this.section === 'ProjectListPage' || this.section === 'ProjectPage' }"><i
              class="fa-solid fa-briefcase icons" style=" margin-right: 12px"></i>Meus projetos</a>
        </li>
        <li>
          <a class="nav-link" href="/encontre-talentos"
            :class="{ active: this.section === 'AssistantListPage' || this.section === 'AssistantPage' || this.section === 'ProposalPage' }"><i
              class="fa-solid fa-magnifying-glass icons" style=" margin-right: 12px"></i>Encontre
            talentos</a>
        </li>
        <!-- <li>
          <a
            class="nav-link"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#support"
            ><i class="fa-solid fa-headset icons"></i>Suporte</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import Config from "../../../resources/config.js";

export default {
  data() {
    return {};
  },
  computed: {
    section() {
      return this.$store.state.activeSection;
    },
    rating() {
      let user = this.$store.state.activeUser;
      if (user) {
        return user.feedback_rating;
      }
      return 0;
    },
    userName() {
      let user = this.$store.state.activeUser;
      if (user) {
        return user.name;
      }
      return null;
    },
    userPicture() {
      let user = this.$store.state.activeUser;
      if (user && user.picture) {
        return this.getImageURL(user.picture);
      }
      return null;
    },
    logoutURL() {
      return `${Config.apiHost}/logout`;
    },
  },

  methods: {},
};
</script>
