<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="alert alert-warning" style="margin-bottom: 30px" v-if="project.status == 1">
          Seu projeto está em análise. Assim que for aprovado, ele ficará visível na plataforma e poderá receber
          propostas.
        </div>
        <div class="alert alert-warning" style="margin-bottom: 30px" v-else-if="project.status == 2 && project.expiring">
          Seu projeto será automaticamente encerrado em {{ formatTimestamp(project.expiration_timestamp) }}. Se você deseja manter seu projeto ativo por mais 15 dias, você deve iniciar o contato com pelo menos mais uma assistente.
        </div>
        <div class="alert alert-danger" style="margin-bottom: 30px" v-else-if="project.status == 3">
          Seu projeto está inativo e você não poderá mais receber propostas ou contactar assistentes.<br /> Entre em
          contato com o suporte para maiores informações.
        </div>

        <div class="row" style="margin: 0">
          <div class="col-sm-12" style="padding:0">
            <div class="tab">
              <h1>Projeto</h1>
            </div>
          </div>
        </div>
        <div class="project-container project_area section" style="margin: 0">
          <div :class="{'left': project.client_id}">
            <div class="project_info">
              <div class="project_heading break-word">
                <h2 class="mb-0">{{ project.title }}</h2>
                <p class="date m-0 p-0 mb-2" style="font-size: 10pt">
                  ID: {{ project.id }}
                </p>
                <p class="date">
                  Última atualização:
                  {{ formatTimestamp(project.last_update_timestamp) }}
                </p>
              </div>

              <div class="wrap-box">
                <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(project.description)">
                </p>
                <label></label>
              </div>

            </div>
          </div>
          <div class="right" v-if="project.client_id">
            <div class="proposal box mt-0">
              <h1>Propostas recebidas</h1>
              <h1 class="proposal_number">{{ project.active_proposals }}</h1>
            </div>
            <div class="status box mb-0">
              <h1>
                Status:
                <span class="btn" :class="getBtnStatusClass(project.status)">{{
                  formatProjectStatus(project.status)
                }}</span>
              </h1>
            </div>
          </div>
        </div>
        <div v-if="project.status === 4 && project.client_id">
          <div class="alert alert-warning mt-4" v-if="project.released_contacts === 0">Este projeto foi encerrado e você
            não solicitou nenhum contato.</div>
          <div v-else>
            <div class="alert alert-warning mt-4">Este projeto foi encerrado e você solicitou o contato de {{
              formatPlural(project.released_contacts, "assistente", "assistentes")
            }}.</div>
          </div>
          <div style="margin: 20px 0 20px 0">
              <div class="proposal-page" style="padding:0">
                <ProposalList :project="this.project" :proposalCount="proposalCount" :hideTitle="false" />
              </div>
            </div>

        </div>
        <div style="margin: 20px 0 20px 0" v-else-if="project.status !== 1 && project.client_id">
          <div class="proposal-page" style="padding:0">
            <ProposalList :project="this.project" :proposalCount="proposalCount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import ProposalList from "../project/ProposalList.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";


const RESULT_COUNT = 10;

export default {
  components: { SideBar, TopBar, ProposalList },

  created() {
    this.$store.commit("setActiveSection", "ProjectPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadProject();
  },

  data() {
    return {
      project: null,
      proposalCount: RESULT_COUNT
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },

  methods: {
    async loadProject() {
      this.$store.commit("setLoading", true);

      try {
        let response = await ClientManager.getProject(
          this.$route.params.projectId,
          this.proposalCount
        );
        let profile = response.client;
        profile.contact_info = JSON.parse(profile.contact_info);

        this.project = response.project;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
        let ctx = this;
        this.applyTruncation(() => {
          ctx.initTooltips();
        });
      } catch (error) {
        this.handleAPIError(error, "projeto/" + this.$route.params.projectId);
      }
    },
  },
};
</script>
