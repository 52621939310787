import TimeUtils from "../utils/time.js";
import Config from "../../../resources/config.js";

const $ = window["$"];
let tooltipList = [];
let popoverList = [];

function updateWrapBoxes() {
    $(".wrap-box").each(function (index, item) {
        updateWrapBox(item);
    });
}

function updateWrapBox(item) {
    let $box = $(item);
    let p = $box.children("p").eq(0)[0];
    let $label = $box.children("label");

    if (p.scrollHeight > p.clientHeight) {
        $label.show();
    } else {
        if ($box.is('.unclamp')) {
            $label.hide();
            $box.removeClass("unclamp");
        } else {
            $label.hide();
        }
    }
}


$(document).on('click', '.wrap-box label', function () {
    let $wrapBox = $(this).closest('.wrap-box');
    $wrapBox.toggleClass("unclamp");
});

$(window).on('resize', updateWrapBoxes);

export default {
    methods: {

        getImageURL(file) {
            return `${Config.assetsHost}/${file}`;
        },

        formatPlural(count, singular, plural) {
            if (count === 1) {
                return count + " " + singular;
            } else {
                return count + " " + plural;
            }
        },

        formatElapsedTime(time) {

            let seconds = Math.floor((new Date() - time) / 1000);
            let intervalType;
            if (seconds === 0) {
                return 'Agora mesmo';
            }

            let interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = 'ano';
            } else {
                interval = Math.floor(seconds / 2592000);
                if (interval >= 1) {
                    if (interval > 1) {
                        intervalType = 'meses';
                    } else {
                        intervalType = 'mês';
                    }
                } else {
                    interval = Math.floor(seconds / 86400);
                    if (interval >= 1) {
                        if (interval > 1) {
                            intervalType = 'dias';
                        } else {
                            intervalType = 'dia';
                        }
                    } else {
                        interval = Math.floor(seconds / 3600);
                        if (interval >= 1) {
                            if (interval > 1) {
                                intervalType = 'horas';
                            } else {
                                intervalType = 'hora';
                            }
                        } else {
                            interval = Math.floor(seconds / 60);
                            if (interval >= 1) {
                                if (interval > 1) {
                                    intervalType = 'minutos';
                                } else {
                                    intervalType = 'minuto';
                                }
                            } else {
                                interval = seconds;
                                if (interval > 1) {
                                    intervalType = 'segundos';
                                } else {
                                    intervalType = 'segundo';
                                }
                            }
                        }
                    }
                }
            }
            return interval + ' ' + intervalType + ' atrás';
        },

        formatDuration(loanDurationInSeconds) {
            return TimeUtils.formatDuration(loanDurationInSeconds);
        },

        formatTimestamp(timestamp) {
            let date = new Date(timestamp);
            return date.toLocaleDateString("pt-BR") + " " + date.toLocaleTimeString("pt-BR")
        },

        getRatingStars(rating) {
            let ret = [];
            for (let i = 0; i < 5; i++) {
                ret.push({ id: i, class: 'fa-regular fa-star' });
            }
            if (rating >= 0.5 && rating < 1) {
                ret[0].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 1 && rating < 1.5) {
                ret[0].class = 'fa-solid fa-star';
            } else if (rating >= 1.5 && rating < 2) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 2 && rating < 2.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
            } else if (rating >= 2.5 && rating < 3) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 3 && rating < 3.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
            } else if (rating >= 3.5 && rating < 4) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 4 && rating < 4.5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
            } else if (rating >= 4.5 && rating < 5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
                ret[4].class = 'fa-regular fa-star-half-stroke';
            } else if (rating >= 5) {
                ret[0].class = 'fa-solid fa-star';
                ret[1].class = 'fa-solid fa-star';
                ret[2].class = 'fa-solid fa-star';
                ret[3].class = 'fa-solid fa-star';
                ret[4].class = 'fa-solid fa-star';
            }

            return ret;
        },

        formatMultilineText(text) {
            return $('<div/>').text(text).html().replace(new RegExp('\r?\n', 'g'), '<br />');
        },

        formatAnnualizedReturn(loanAmount, loanInterest, loanDurationInSeconds) {
            if (!loanAmount || !loanInterest || !loanDurationInSeconds) {
                return "-";
            }

            loanAmount = Number(loanAmount);
            loanInterest = Number(loanInterest);
            loanDurationInSeconds = Number(loanDurationInSeconds);

            let loanDays = loanDurationInSeconds / (24 * 3600);
            let totalReturn = (loanAmount + loanInterest) / loanAmount;
            let arr = Math.pow(totalReturn, (365 / loanDays)) - 1;

            return parseFloat(arr * 100).toFixed(4) + "%";
        },


        formatTotalAmount(loan) {
            if (!loan || !loan.amount || !loan.interest) {
                return "-";
            }

            return this.formatPrice(BigInt(loan.amount) + BigInt(loan.interest));
        },

        formatNumber(number) {
            return intlTelInputUtils.formatNumber(number, null, intlTelInputUtils.numberFormat.INTERNATIONAL)
        },

        buildSessionLink() {
            return "Sua sessão expirou.<br/><a onclick='window.global.popupWindow(\"" + Config.apiHost + "/auth/google/client?popup=true\",\"_blank\",window, 600, 400)' target='_blank' class='link'> Clique aqui para entrar novamente</a>";
        },

        formatProjectStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Desativado";
                case 4:
                    return "Encerrado";
                default:
                    return "Desconhecido";
            }
        },

        getBtnStatusClass(status) {
            switch (status) {
                case 1:
                    return "bg-warning";
                case 2:
                    return "bg-success";
                case 3:
                    return "bg-danger";
                case 4:
                    return "bg-orange";
                default:
                    return "bg-dark";
            }
        },

        getTextStatusClass(status) {
            switch (status) {
                case 1:
                    return "text-warning";
                case 2:
                    return "text-success";
                case 3:
                    return "text-danger";
                case 4:
                    return "text-orange";
                default:
                    return "text-dark";
            }
        },

        handleAPIError(error, sourcePage) {
            console.log(error);
            if (error.status === 401) {
                window.location = Config.loginPage + (sourcePage ? "?next=" + sourcePage : "");
            } else if (error.errorCode === "CLIENT_NOT_FOUND") {
                window.location = Config.signupPage + "?nome=" + error.errorData;
            } else {
                this.$store.commit("setError", error);
                this.$store.commit("setLoading", false);
            }
        },

        showError(err) {
            console.log(err);
            let error;
            if (err.data && err.data.data && err.data.data.message) {
                error = err.data.data.message;
            } else if (err.message) {
                error = err.message;
            } else {
                error = err;
            }

            this.$store.commit("setLoading", false);

            $("#errorMsg").html(this.formatMultilineText(error));
            $("#errorModal").modal("show");

        },

        showSuccess(msg) {
            $("#successMsg").html(this.formatMultilineText(msg));
            $("#successModal").modal("show");
        },

        showConfirmation(title, confirmationMessage, confirmButtonTitle, confirmAction, successMessage, errorMessage) {
            let $confirmModal = $("#confirmModal");
            $confirmModal.find("[data-title]").text(title);
            $confirmModal.find("[data-msg]").text(confirmationMessage);

            this.showModal($confirmModal, function () { }, confirmButtonTitle, confirmAction, successMessage, errorMessage);
        },

        showModal(modal, init, confirmButtonTitle, confirmAction, successMessage, errorMessage, closeAction) {
            let $ctx = this;
            let $modal = $(modal);
            let $modalError = $modal.find('[data-error]')
            let $btnConfirmAction = $modal.find('[data-confirm]');

            $modalError.hide();
            $btnConfirmAction.text(confirmButtonTitle);

            $modal.data('initFunction', function () {
                $('html').addClass('noscroll');
                init($modal);
            });

            $modal.off().on('hidden.bs.modal', function () {
                $('html').removeClass('noscroll');
                $modal.data('initFunction', 'null');
                if (closeAction) {
                    closeAction();
                }
            });

            $btnConfirmAction.off().on("click", async function () {
                try {
                    $modal
                        .find("input, textarea, button, .btn")
                        .addClass("disabled")
                        .prop("disabled", true);

                    $btnConfirmAction.text("Por favor, aguarde...");
                    $modalError.hide();

                    if (await confirmAction()) {
                        $modal.modal("hide");
                        $("#successMsg").html(successMessage);
                        $("#successModal").modal("show");
                    }
                } catch (error) {
                    console.log(error);
                    if (error.status === 401) {
                        $modalError.html($ctx.buildSessionLink()).show();
                    } else if (error.errorCode === "INVALID_CLIENT_STATUS" && error.errorData === 3) {
                        $modalError.html("Seu perfil foi desativado.\nEntre em contato com o suporte para maiores informações.").show();
                    } else if (error.errorCode === "INVALID_ASSISTANT_STATUS" && error.errorData === 3 || error.errorCode === "ASSISTANT_NOT_FOUND") {
                        $modalError.html("O perfil da assistente não está mais disponível.").show();
                    } else if (error.errorCode === "MAX_CONTACTS_EXCEEDED") {
                        $modalError.html("Você atingiu o limite máximo de contatos para este projeto.").show();
                    } else if (error.errorCode === "PROPOSAL_NOT_FOUND") {
                        $modalError.html("A proposta não está mais disponível.").show();
                    } else {
                        $modalError.html($ctx.formatMultilineText(errorMessage)).show();
                    }
                } finally {
                    $modal
                        .find("input, textarea, button, .btn")
                        .removeClass("disabled")
                        .prop("disabled", false);

                    $btnConfirmAction.text(confirmButtonTitle);
                }
            });

            $modal.modal("show");

        },

        showCreateProject(clientManager, assistant) {
            let $ctx = this;
            let $modal = $("#createProjectModal");
            $modal.find(".item").removeClass("error");
            $modal.find("[data-project-title]").val('');
            $modal.find("[data-project-description]").val('');


            let $description = $modal.find("[data-project-description]");
            let title;
            if (assistant) {
                title = $('<span>Convidar <strong data-assistant-name style="color: var(--primary-color)"></strong> para um projeto</span>');
                title.find('strong').text(assistant.name);
            } else {
                title = $('<span>Postar projeto<span>');
            }
            $modal.find('.modal-title').html(title[0].innerHTML);

            let action = async function () {
                let projectDetails = {
                    title: $modal.find("[data-project-title]").val(),
                    description: $modal.find("[data-project-description]").val(),
                };

                let hasError = false;
                $modal.find(".item").removeClass("error");

                if (projectDetails.title.length < 10) {
                    $modal
                        .find("[data-project-title]")
                        .closest(".item")
                        .addClass("error");
                    hasError = true;
                }

                if (projectDetails.description.length < 50) {
                    $description.closest(".item").addClass("error");
                    $description.closest('.item').find('em').text('Por favor, informe uma descrição com pelo menos 50 caracteres.');
                    hasError = true;
                } else if ($ctx.hasContactInfo(projectDetails.description)) {
                    $description.closest('.item').addClass('error');
                    $description.closest('.item').find('em').text('Por favor, não informe dados de contato ou links no seu projeto.');
                    hasError = true;
                }

                let $chk = $modal.find('[data-chk-terms]');
                if (!$chk.prop('checked')) {
                    $chk
                        .closest(".item")
                        .addClass("error");
                    hasError = true;
                }

                if (hasError) {
                    return false;
                }

                let project = await clientManager.addProject(projectDetails, assistant);
                if ($ctx.$store.state.activeProjectList) {
                    $ctx.$store.state.activeProjectList.unshift(project);
                }
                return true;
            };

            this.showModal(
                $modal,
                function () {
                    $modal.find("[data-project-title]").focus();
                },
                "Postar",
                action,
                "Projeto criado.",
                "Ocorreu um erro criar o projeto.\nPor favor, tente novamente.\nSe o problema persistir, atualize a página."
            );

        },

        showInviteProject(clientManager, assistant, projects) {
            let $modal = $("#inviteAssistantToProjectModal");
            $modal.find(".item").removeClass("error");
            let $selector = $modal.find('[data-project-list]');
            $selector.html('');

            let title;
            title = $('<span>Convidar <strong data-assistant-name style="color: var(--primary-color)"></strong> para um projeto</span>');
            title.find('strong').text(assistant.name);
            $modal.find('.modal-title').html(title[0].innerHTML);

            projects.forEach(p => {

                let $op = $('<option value="' + p.id + '">');
                $op.text(p.title);
                $selector.append($op);
            });

            let action = async function () {
                let hasError = false;

                if (hasError) {
                    return false;
                }

                await clientManager.inviteAssistant(assistant.id, $selector.val());
                return true;
            };

            this.showModal(
                $modal,
                function () {
                    $modal.find("[data-project-list]").focus();
                },
                "Convidar",
                action,
                "Convite enviado.",
                "Ocorreu um erro convidar a assistente.\nPor favor, tente novamente.\nSe o problema persistir, atualize a página."
            );

        },

        showInviteAssistant(clientManager, assistant, projects) {
            let $ctx = this;

            if (projects.length === 0) {
                $ctx.showCreateProject(clientManager, assistant);
            } else {
                let $modal = $("#inviteAssistantModal");
                $modal.find('[data-new-project]').off().on('click', function () {
                    $modal.modal('hide');
                    $ctx.showCreateProject(clientManager, assistant);
                });
                $modal.find('[data-existing-project]').off().on('click', function () {
                    $modal.modal('hide');
                    $ctx.showInviteProject(clientManager, assistant, projects);
                });


                $modal.data('initFunction', function () {
                    $('html').addClass('noscroll');
                    init($modal);
                });

                $modal.off().on('hidden.bs.modal', function () {
                    $('html').removeClass('noscroll');
                    $modal.data('initFunction', 'null');
                    if (closeAction) {
                        closeAction();
                    }
                });

                $modal.modal('show');
            }
        },

        showContactAssistant(clientManager, client, project, proposal) {
            let $modal = $("#contactAssistantModal");
            $modal.find(".item").removeClass("error");

            $modal.find('[data-assistant-name]').text(proposal.name);
            $modal.find('[data-client-whatsapp]').text(this.formatNumber(client.contact_info.whatsapp));

            let count = project.max_contacts - project.released_contacts;

            if (count > 1) {
                $modal.find('[data-contacts-left]').text(this.formatPlural(count - 1, 'contato', 'contatos'));
                $modal.find('[data-has-contacts-left]').show();
                $modal.find('[data-no-contacts-left]').hide();
            } else {
                $modal.find('[data-max-contacts]').text(this.formatPlural(project.max_contacts, 'contato', 'contatos'));
                $modal.find('[data-has-contacts-left]').hide();
                $modal.find('[data-no-contacts-left]').show();
            }

            let action = async function () {
                let hasError = false;

                let $chk = $modal.find('[data-chk-terms]');
                if (!$chk.prop('checked')) {
                    $chk
                        .closest(".item")
                        .addClass("error");
                    hasError = true;
                }

                if (hasError) {
                    return false;
                }

                let updatedProject = await clientManager.releaseContact(proposal.assistant_id, proposal.project_id);
                project.released_contacts++;
                proposal.released = true;
                if (updatedProject !== null) {
                    project.expiring = updatedProject.expiring;
                    project.expiration_timestamp = updatedProject.expiration_timestamp;
                }
                return true;
            };

            let msg = $('<span>Você liberou seus dados de contatos para <strong data-assistant-name></strong>.<br/><br/> Aguarde ser contactado via WhatsApp.</span>');
            msg.find('[data-assistant-name]').text(proposal.name);

            this.showModal(
                $modal,
                function () {
                },
                "Confirmar",
                action,
                msg,
                "Ocorreu um erro ao iniciar o contato.\nPor favor, tente novamente.\nSe o problema persistir, atualize a página."
            );
        },

        hasContactInfo(text) {
            let emailPat = /(\".*\"|[A-Za-z]\w*)@(\[\d{1,3}(\.\d{1,3}){3}]|[A-Za-z]\w*(\.[A-Za-z]\w*)+)/g
            let phonePart = /[0-9\s\-\()]+/g;
            let urlPat = "[A-Za-z]+://[A-Za-z0-9-_]+\\.[A-Za-z0-9-_%&\?\/.=]+";
            if (text.match(emailPat)) {
                return true;
            }

            let words = text.split(" ");
            if (words) {

                for (let word of words) {
                    if (word.replace(/\D/g, "").length >= 8) {
                        return true;
                    }
                }
            }

            let m = text.match(phonePart);
            if (m) {
                for (let word of m) {
                    if (word.replace(/\D/g, "").length >= 8) {
                        return true;
                    }
                }
            }

            if (text.match(urlPat)) {
                return true;
            }

            return false;
        },

        getCervAVTitle() {
            return 'Profissional certificado em <br/>Assistência Virtual';
        },

        getCertAVDescription() {
            return 'Este é um treinamento introdutório em Assistência Virtual fornecido pela Virtap.<br/><br/> Elaborado por profissionais que acumulam 10 anos de experiência no mercado.';
        },

        getCervAPTitle() {
            return 'Profissional certificado em <br/> Assessoria Pessoal';
        },

        getCertAPDescription() {
            return 'Fornecido pela Virtap, este é o treinamento mais completo em Assistência Pessoal do Brasil.<br/><br/> Elaborado por profissionais que acumulam 10 anos de experiência no mercado.';
        },

        initTooltips() {
            tooltipList.forEach((tooltip) => { tooltip.dispose() });
            let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            });

            popoverList.forEach((popover) => { popover.dispose() });
            let popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
            popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {

                popperConfig(defaultBsPopperConfig) {
                    const newPopperConfig = { strategy: 'fixed' }
                    defaultBsPopperConfig.modifiers[0].enabled = false;

                    // use defaultBsPopperConfig if needed...
                    return defaultBsPopperConfig
                }
            }))
        },

        applyTruncation(callback) {
            this.$nextTick(function () {
                setTimeout(updateWrapBoxes, 400);
                if (callback) {
                    setTimeout(callback, 400);
                }
            });;

        }
    }
}