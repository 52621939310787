<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="element">
          <div class="tab">
            <h1>Assistente</h1>
          </div>
          <Assistant :item="assistant" :hideDescription="true" :projects="projects" class="section" />

          <div class="row gy-4 certs" style="margin-top:30px;" v-if="assistant.cert_av || assistant.cert_ap">
            <div class="col-sm-12 col-md-12 cert-item" v-if="assistant.cert_av">
              <div class="certav-box"><strong>Profissional certificado em Assistência Virtual</strong>
                <div class="cert-container">
                  <i class="fa fa-laptop cert_av"></i>
                  <div>
                    <div class="break-word"> Este é um treinamento introdutório em Assistência Virtual fornecido pela
                      Virtap.</div>

                    <div class="break-word">Elaborado por profissionais que
                      acumulam 10 anos de
                      experiência no mercado.</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 cert-item" v-if="assistant.cert_ap">
              <div class="certap-box">
                <strong>Profissional
                  certificado em Assessoria Pessoal</strong>
                <div class="cert-container">
                  <i class="fa fa-star cert_ap"></i>
                  <div>
                    <div class="break-word"> Fornecido pela Virtap, este é o treinamento mais
                      completo em Assistência Pessoal do Brasil.</div>

                    <div class="break-word">Elaborado por profissionais que
                      acumulam 10 anos de
                      experiência no mercado, este treinamento é composto por mais de 60 aulas divididas em 9 módulos, que
                      vão desde a execução
                      de tarefas, ferramentas, até o aprendizado de softskills que são essenciais para se trabalhar nessa
                      área. </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card">
            <div class="card-body">
              <i class="fa fa-star cert_ap"></i>Certificação em Assessoria Pessoal
            </div>
          </div> -->

          <div style="margin-top: 30px">
            <div class="tab">
              <h1>Sobre mim</h1>
            </div>
            <div class="description-part section">
              <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(assistant.description)">
              </p>
            </div>
          </div>


          <div class="seller_review">
              <div class="tab">
                <h1>Avaliações recebidas de clientes <span class="text-muted fs-6">({{assistant.reviews.length}})</span></h1>
              </div>
              <div class="section" style="padding:0" v-if="assistant.reviews.length > 0">
                <div class="proposals-list"  style="height: auto;max-height: 100%; padding-bottom: 0">
                  <div class="proposal-container">
                    <div class="proposal" style="padding:20px; cursor: pointer" v-for="review in assistant.reviews" :key="review.project_id" @click="showProject(review.project_id)">
                      <div class="proposal-info">
                          <p class="name break-all" style="font-size:14.2px">{{review.title}}</p>
                          <p class="project" style="font-style: italic; font-size: 9pt;  display: flex; gap: 5px; flex-wrap: wrap; align-items: center">
                            <img class="freelancer_img" style="width:50px; height: 50px;" :src="getImageURL(review.picture)" v-if="review.picture"/>
                            <img class="empty-image" style="width:50px; height: 50px;" v-else/>
                            <span class="break-all">{{review.name}}</span>
                          </p>
                          <p class="project mt-1" style="margin-left: -5px">
                            <i :class="star.class" class="icons" v-for="star in getRatingStars(review.assistant_rating)" :key="star.id" style="font-size: 15px"></i>
                                <span class="rating"> ({{ review.assistant_rating.toFixed(2) }})</span>
                          </p>
                          <p class="date">{{formatElapsedTime(review.release_timestamp)}}</p>
                          <p class="project mt-2 break-all break-line">{{review.assistant_comment}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section text-center" v-else>
                <h2 class="light p-2">
                  <div class="d-flex" style="align-items: center; gap: 5px; flex-wrap: wrap; justify-content: center">
                    <i class="fa-regular fa-star" style="font-size: 30px"></i>
                    <span>Nenhuma avaliação</span>
                  </div>
                </h2>
              </div>
            </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import Assistant from "../assistant/Assistant.vue";
import ClientManager from "../../../js/services/client.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, Assistant },

  created() {
    this.$store.commit("setActiveSection", "AssistantPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadAssistantProfile();
  },

  data() {
    return {
      assistant: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadAssistantProfile() {
      this.$store.commit("setLoading", true);

      try {
        let response = await ClientManager.getAssistantProfile(
          this.$route.params.assistantId
        );
        let profile = response.client;
        profile.contact_info = JSON.parse(profile.contact_info);

        this.assistant = response.assistant;
        this.projects = response.projects;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
        let ctx = this;
        this.$nextTick(() => {
          ctx.initTooltips();
        })
      } catch (error) {
        this.handleAPIError(
          error,
          "assistente/" + this.$route.params.assistantId
        );
      }
    },

    showProject(project_id) {
      window.open('/projeto/' + project_id);
    },

  },
};
</script>
